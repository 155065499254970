import React, { useEffect, useState } from "react";
import styles from "./singleproject.module.scss";
import arrowDown from "../../assets/images/arrowGray.png";
import { Button, Image, Modal } from "react-bootstrap";
import Img from "gatsby-image";
import { Link, navigate } from "gatsby";
import arrowViolet from "../../assets/images/arrowViolet.png";
import { useRecoilValue } from "recoil";
import { ModeSelector } from "../../recoil/selectors";
import projects from "../../utils/projects";
import Swiper from "react-id-swiper";

const SingleProjectComp = ({ images, slug }) => {
  const [displayProject, setDisplayProject] = useState(false);
  const [show, setShow] = useState(false);
  const [modalImg, setModalImg] = useState(false);
  const [prevProject, setPrevProject] = useState(false);
  const [nextProject, setNextProject] = useState(false);
  const mode = useRecoilValue(ModeSelector);
  
  const params = {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    initialSlide: "1",
    slidesPerView: "1.2",
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
  };

  useEffect(()=>{
    let hardCodedProyects = projects(images)
    let last = hardCodedProyects.length-1
    let idx = hardCodedProyects.findIndex(pro => pro.slug === slug)

    if(idx === -1)navigate("/projects");
    
    switch (idx) {
      case 0:
        setDisplayProject(hardCodedProyects[0]);
        setPrevProject(hardCodedProyects[hardCodedProyects.length-1]);
        setNextProject(hardCodedProyects[1]);
        break;
      case Number(last):
        setDisplayProject(hardCodedProyects[last]);
        setPrevProject(hardCodedProyects[hardCodedProyects.length-1]);
        setNextProject(hardCodedProyects[0]);
        break;
      default:
            setDisplayProject(hardCodedProyects[idx])
            setPrevProject(hardCodedProyects[idx-1]);
            setNextProject(hardCodedProyects[idx+1]);
        break;
    }
    
   
  },[images,slug])

  useEffect(() => {
    window.scrollTo({top:0, behavior:'smooth'})

  });


  return (
    <>
      {displayProject && (
        <div className={styles.singleprojectcont}>
          <div className={styles.firstrow}>
            <div className={styles.textcol}>
              <Link to="/projects" className={styles.arrowLink}>
                <Button className={styles.ButtonArrow}>
                  <img alt="arrow" src={arrowDown} className={styles.Arrow} />
                </Button>
                <small className={styles.smalltxt}>Volver a proyectos {}</small>
              </Link>
              <div>
                <div style={{ width: "100%", textAlign: "left" }}>
                  {" "}
                  <small style={{ color: "grey" }}>
                    {mode === "light" ? ( <Link style={{ color: "black" }} to="/projects">
                      Proyectos{" "}
                    </Link>):( <Link style={{ color: "white" }} to="/projects">
                      Proyectos{" "}
                    </Link>)}
                    <span>/</span> {displayProject.brand}
                  </small>
                </div>
                <h1>
                  {displayProject.title}
                  <span>.</span>
                </h1>
                <div  style={{paddingBottom: "2rem"}}>{displayProject.description}</div>
              </div>
              <div className={styles.detailcont}>
                <div className={styles.scopecont}>
                  <small style={{ fontSize: "8pt", color: "gray" }}>
                    SCOPE
                  </small>
                  {displayProject.tags.map((tag, key) => {
                    return <small key={key}>{tag}</small>;
                  })}
                </div>
                <div className={styles.yearcont}>
                  <small>
                    Año |{" "}
                    <small style={{ color: "gray" }}>
                      {displayProject.year}
                    </small>
                  </small>
                  <small>
                    País |{" "}
                    <small style={{ color: "gray" }}>
                      {displayProject.country}
                    </small>
                  </small>
                </div>
              </div>
            </div>
            <div className={styles.imgcol}>
              <Img fluid={displayProject.images['first'].childImageSharp.fluid} alt="Principal" className={styles.first} />
            </div>
          </div>
          <div className={styles.secondrow}>
            <Img
              // style={{ height: "100%", width: "100%"}}
              // imgStyle={{ objectFit: "cover",position: "unset" }}
              fluid={displayProject.images['banner'].childImageSharp.fluid}
              className={styles.fluidimg}
            />
          </div>
          <div className={styles.thirdrow}>
          </div>
          <div className={styles.gallerycontainer}>
              <div className={`${styles.galleryrowt} onlydesktop`}>
                <div className={`${styles.itemt} onlydesktop`}>
                  <Img
                    style={{ height: "100%", width: "100%" }}
                    imgStyle={{ objectFit: "cover" }}
                    fluid={displayProject.images['gallery1'].childImageSharp.fluid}
                    onClick={() => {
                      setModalImg(displayProject.images['gallery1'].childImageSharp.fluid);
                      setShow(true);
                    }}
                  />
                </div>
                <div className={`${styles.itemt} onlydesktop`}>
                  <Img
                    style={{ height: "100%", width: "100%" }}
                    imgStyle={{ objectFit: "cover" }}
                    fluid={displayProject.images['gallery2'].childImageSharp.fluid}
                    onClick={() => {
                      setModalImg(displayProject.images['gallery2'].childImageSharp.fluid);
                      setShow(true);
                    }}
                  />
                </div>
                <div className={`${styles.itemt} onlydesktop`}>
                  <Img
                    style={{ height: "100%", width: "100%" }}
                    imgStyle={{ objectFit: "cover" }}
                    fluid={displayProject.images['gallery3'].childImageSharp.fluid}
                    onClick={() => {
                      setModalImg(displayProject.images['gallery3'].childImageSharp.fluid);
                      setShow(true);
                    }}
                  />
                </div>
              </div>

              {/* Swipper Only Mobile */}
              {displayProject.images && (
                 <Swiper containerClass={`${styles.galleryrowswiper}`} {...params}>
                      <div className={styles.swiperImgs}>
                        <div className={styles.img}>
                          <Img
                            style={{ height: "100%", width: "100%" }}
                            imgStyle={{ objectFit: "cover" }}
                            fluid={displayProject.images['gallery1'].childImageSharp.fluid}
                            onClick={() => {
                              setModalImg(displayProject.images['gallery1'].childImageSharp.fluid);
                              setShow(true);
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.swiperImgs}>
                        <div className={styles.img}>
                          <Img
                            style={{ height: "100%", width: "100%" }}
                            imgStyle={{ objectFit: "cover" }}
                            fluid={displayProject.images['gallery2'].childImageSharp.fluid}
                            onClick={() => {
                              setModalImg(displayProject.images['gallery2'].childImageSharp.fluid);
                              setShow(true);
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.swiperImgs}>
                        <div className={styles.img}>
                          <Img
                            style={{ height: "100%", width: "100%" }}
                            imgStyle={{ objectFit: "cover" }}
                            fluid={displayProject.images['gallery3'].childImageSharp.fluid}
                            onClick={() => {
                              setModalImg(displayProject.images['gallery3'].childImageSharp.fluid);
                              setShow(true);
                            }}
                          />
                        </div>
                      </div>
                 </Swiper>
              )}

            <div className={styles.galleryrowh}>
                <div className={styles.itemh}>
                  <Img
                    style={{ height: "100%", width: "100%" }}
                    imgStyle={{ objectFit: "cover" }}
                    fluid={displayProject.images['gallery4'].childImageSharp.fluid}
                    onClick={() => {
                      setModalImg(displayProject.images['gallery4']);
                      setShow(true);
                    }}
                    />
                </div>
                <div className={styles.itemh}>
                  <Img
                    style={{ height: "100%", width: "100%" }}
                    imgStyle={{ objectFit: "cover" }}
                    fluid={displayProject.images['gallery5'].childImageSharp.fluid}
                    onClick={() => {
                      setModalImg(displayProject.images['gallery5']);
                      setShow(true);
                    }}
                  />
                </div>
            </div>
          </div>
          <hr />
          <p className={styles.suptext}>Siguientes proyectos</p>
          <br />
          <div className={styles.projectprevcontainer}>
            {prevProject && (
              <div className={styles.projectlink}>
                <Link
                  to={`/project/${prevProject.slug}`}
                  
                >
                  <div className={styles.projectprev}>
                    <div className={styles.overlayPrev}>
                      <Img className={styles.imgPrev}
                      fluid={prevProject.images['portada'].childImageSharp.fluid}
                      />
                      <div className={styles.title}>
                        <div>
                          <div className={styles.scopecont}>
                            {prevProject.tags.map((tag, key) => {
                              if (key === prevProject.tags.length - 1) {
                                return <small key={key}>{tag}</small>;
                              } else {
                                return <small key={key}> {tag} |</small>;
                              }
                            })}
                          </div>
                          <h2>{prevProject.title}</h2>
                        </div>
                        <div>
                          <Link
                            to={`/project/${prevProject.slug}`}
                            className={styles.ContainerLink}
                            
                          >
                            <img
                              alt="arrow"
                              src={arrowViolet}
                              className={styles.ArrowBefore}
                            />
                            <p>Ver más</p>
                            <img
                              alt="arrow"
                              src={arrowViolet}
                              className={styles.ArrowAfter}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )}
            {nextProject && (
              <div className={styles.projectlink}>
              <Link
                to={`/project/${nextProject.slug}`}
                className={styles.projectlink}
                
              >
                <div
                  
                  className={styles.projectprev}
                >
                  <div
                  
                  className={styles.overlayPrev}>
                   <Img
                    
                    className={styles.imgPrev}
                    fluid={nextProject.images['portada'].childImageSharp.fluid}
                  />
                    <div className={styles.title}>
                      <div>
                        <div className={styles.scopecont}>
                          {nextProject.tags.map((tag, key) => {
                            if (key === nextProject.tags.length - 1) {
                              return <small key={key}>{tag}</small>;
                            } else {
                              return <small key={key}> {tag} |</small>;
                            }
                          })}
                        </div>
                        <h2>{nextProject.title}</h2>
                      </div>
                      <div>
                        <Link
                          to={`/project/${nextProject.slug}`}
                          className={styles.ContainerLink}
                          
                        >
                          <img
                            alt="arrow"
                            src={arrowViolet}
                            className={styles.ArrowBefore}
                          />
                          <p>Ver más</p>
                          <img
                            alt="arrow"
                            src={arrowViolet}
                            className={styles.ArrowAfter}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: "0", background: "transparent" }}>
          <Image src={modalImg} style={{ width: "101%", height: "104%" }} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SingleProjectComp;
