import React, { Fragment, useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import { IsMobileDevice, CleanArrayImages } from "../utils/functions";
import { useRecoilState } from "recoil";
import { isMobile, Mode } from "../recoil/atoms";
import SingleProjectComp   from '../components/SingleProject/SingleProjectComp'
import SEO from "../components/seo";

const SingleProject = ({data,pageContext}) => {
  const [images, setImages] = useState(false);
  const [, setIsMobile] = useRecoilState(isMobile);
  const [, setMode] = useRecoilState(Mode);
  
  useEffect(() => {
    setIsMobile(IsMobileDevice());
    setMode(localStorage.theme);
    setImages(CleanArrayImages(data));
  }, [data,setIsMobile,setMode]);

  return (
    <Fragment>
      <SEO title={`${pageContext.title}`} description="Somos una startup con base en Buenos Aires que provee equipos on demand de alta calidad para trabajar en proyectos de innovación y tecnología, con foco en diseño UX, desarrollo de software e ingeniería."/>
      <Layout>
       {images && <SingleProjectComp images={images} slug={pageContext.slug}/>}
      </Layout>
    </Fragment>
  );
};

export default SingleProject;

export const data = graphql`
  query Project {
    Messi644: allFile(filter: { relativeDirectory: { eq: "Projects/Budweiser/Messi644" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Blasfemia: allFile(filter: { relativeDirectory: { eq: "Projects/Blasfemia" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Escappe: allFile(filter: { relativeDirectory: { eq: "Projects/Escappe" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    JuntosPorSudamerica: allFile(filter: { relativeDirectory: { eq: "Projects/Conmebol/JuntosPorSudamerica" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Lollapalooza: allFile(filter: { relativeDirectory: { eq: "Projects/Budweiser/Lollapalooza" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Patagonia: allFile(filter: { relativeDirectory: { eq: "Projects/Patagonia" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
